import "@/styles/Flatpickr.css";
import "@/styles/HolidaysTable.css";
import "@/styles/TextArea.css";
import "@/styles/globals.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import "draft-js/dist/Draft.css";
import "flatpickr/dist/themes/material_blue.css";
import "leaflet/dist/leaflet.css";
import "nprogress/nprogress.css";
import "react-day-picker/dist/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RootLayout from "@/components/Layouts/RootLayout";
import { GTM_ID } from "@/settings";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import { useEffect, useMemo } from "react";

const GoogleTagManager = dynamic(
  () => import("../components/GoogleTagManager"),
  { ssr: false },
);

const Root = ({ Component, pageProps }) => {
  useEffect(() => {
    /* Remove the server-side injected CSS. */
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    const startLoading = () => NProgress.start();
    const stopLoading = () => NProgress.done();

    Router.events.on("routeChangeStart", startLoading);
    Router.events.on("routeChangeComplete", stopLoading);
    Router.events.on("routeChangeError", stopLoading);

    return () => {
      Router.events.off("routeChangeStart", startLoading);
      Router.events.off("routeChangeComplete", stopLoading);
      Router.events.off("routeChangeError", stopLoading);
    };
  }, []);

  /*
    Suppress deprecation error caused by next-react-svg. We will probably need a new solution for icons in the app.

    TODO: Deal with this when we move to react19
  */
  useEffect(() => {
    const error = console.error;
    console.error = (...args) => {
      if (/defaultProps/.test(args[0])) return;
      error(...args);
    };
  }, []);

  const getLayout = useMemo(() => {
    /* Allow persistent layout sharing (https://nextjs.org/docs/basic-features/layouts) */
    return Component.getLayout || ((page) => page);
  }, [Component]);

  const shouldAddGoogleTagManager = !!GTM_ID && GTM_ID !== "ENTRYPOINT_GTM_ID";

  return (
    <>
      {/* 
        This needs to be placed here
        Reference: https://nextjs.org/docs/messages/no-document-title
      */}
      <Head>
        <title>Talkhub</title>
      </Head>

      {shouldAddGoogleTagManager && <GoogleTagManager gtmId={GTM_ID} />}

      <RootLayout>{getLayout(<Component {...pageProps} />)}</RootLayout>
    </>
  );
};

export default Root;
