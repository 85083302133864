export const GRAPHQL_ENDPOINT = "graphql";
export const SERVICE_STATUS_ENDPOINT = "ENTRYPOINT_SERVICE_STATUS_ENDPOINT";

export const SERVER_SETTINGS = {
  backendHost: "prod.sg.next.api.talkhub.cloud",
  backendSsl: "ENTRYPOINT_TEMPLATE_BACKEND_SSL",
  clientId: "6b2d5e9de827125feddeb560635740fc50c5f78c5ce8107b",
  clientSecret: "3575f7489f90d2392a9fb3ebdd22e679459006253390e754",
  sleekplanProductId: "ENTRYPOINT_SLEEKPLAN_PRODUCT_ID",
  vapidPublicApplicationServerKey:
    "BHclIDRGGCIdJ3szQbOZkH0-uQ0GaGkEFJhPX2hg09I2BDjtE4x6aVd0J10nszPz21Wl5sKU6kaoYyGApNLE8Mo",
};

export const MESSAGE_EDITOR_DEFAULT_CHAR_LIMIT = 4096;

export const CONVERSATION_EVENTS_PAGINATION_LIMIT = 50;
export const DEFAULT_PAGINATION_LIMIT = 30;
export const MAX_PAGINATION_LIMIT = 50;
export const MIN_PAGINATION_LIMIT = 10;
export const MIN_SEARCHTERM_LENGTH = 3;
export const MAX_TAGS_DISPLAYED = 4;
export const MAX_REPORTING_DATE_RANGE_DAYS_LIMIT = 60;

export const DEFAULT_TABLE_PAGINATION_STATE = {
  rowsPerPage: 25,
  selectedPage: 1,
};

// eslint-disable-next-line prettier/prettier
export const SENTRY_DSN = "https://59678dad98314739aac5c9fc7cd68d44@o548169.ingest.sentry.io/5670963";

export const GTM_ID = "ENTRYPOINT_GTM_ID";
