import { createTheme } from "@material-ui/core/styles";

import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import colors from "./colors";

const theme = createTheme({
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },

    primary: {
      contrastText: colors.white,
      lighter: colors.teal[490],
      light: colors.teal[500],
      main: colors.teal[800],
      dark: colors.teal[900],
      darkest: colors.teal[3000],

      410: colors.teal[410],
      480: colors.teal[480],
      700: colors.teal[700],
    },

    secondary: {
      light: colors.blue[500],
      main: colors.blue[600],
      dark: colors.blue[701],

      550: colors.blue[550],
    },

    tertiary: {
      lighter: colors.orange[500],
      light: colors.orange[900],
      main: colors.orange[1000],
      dark: colors.orange[1500],

      700: colors.orange[700],
      800: colors.orange[800],
    },

    textPrimary: {
      main: colors.blue[1800],
      dark: colors.blue[2000],
    },

    textSecondary: {
      main: colors.grey[1700],
      dark: colors.grey[1711],
      darker: colors.grey[1820],

      /* Less-used secondary text colors */
      1000: colors.yellow[1000],
      1740: colors.grey[1740],
      1760: colors.grey[1760],
      2500: colors.grey[2500],
      4000: colors.blue[4000],
    },

    alert: {
      lighter: colors.pink[500],
      light: colors.pink[600],
      main: colors.red[500],
      dark: colors.red[600],

      300: colors.red[300],
      400: colors.red[400],
      420: colors.red[420],
      450: colors.red[450],
      465: colors.red[465],
      500: `${colors.red[500]}${hexTransparencyEnum[11]}`,
    },

    success: {
      light: colors.green[1500],
      main: colors.green[1900],

      1590: colors.green[1590],
      1650: colors.green[1650],
      1700: colors.green[1700],
    },

    link: {
      light: colors.blue[586],
      main: colors.blue[600],
      dark: colors.blue[700],

      650: colors.blue[650],
    },

    boxShadow: {
      main: `${colors.black}${hexTransparencyEnum[16]}`,
      dark: `${colors.black}${hexTransparencyEnum[22]}`,
    },

    disabledInput: {
      main: colors.grey[387],
    },

    primaryBorder: {
      lighter: colors.grey[407],
      light: colors.grey[410],
      main: colors.grey[500],
      dark: colors.grey[630],
      darker: colors.grey[1300],
      darkest: colors.grey[1560],

      625: colors.grey[625],
      1800: colors.blue[1800],
    },

    primaryBackground: {
      lighter: colors.grey[350],
      light: colors.grey[351],
      main: colors.grey[360],
      dark: colors.grey[613],
      darkest: colors.grey[1580],

      /* Less-used colors */
      253: colors.grey[253],
      375: colors.grey[375],
      405: colors.grey[405],
      465: colors.grey[465],
      720: colors.blue[720],
    },

    /* Mostly Hover/Active/Selected/Focus colors */
    primaryAction: {
      lighter: colors.grey[400],
      light: colors.grey[405],
      main: colors.grey[450],
      darkest: colors.grey[1600],

      /* 
        Less-used colors
        or not between the most used lightest and darkest color.
      */
      50: colors.blue[50],
      275: colors.grey[275],
      346: colors.grey[346],
      380: colors.grey[380],
      395: colors.grey[395],
      420: colors.grey[420],
      470: colors.grey[470],
    },

    blue: {
      30: colors.blue[30],
      150: colors.blue[150],
    },

    pink: {
      400: colors.pink[400],
    },

    grey: {
      240: colors.grey[240],
      345: colors.grey[345],
      348: colors.grey[348],
      365: colors.grey[365],
      370: colors.grey[370],
      400: colors.grey[400],
      403: colors.grey[403],
      405: colors.grey[405],
      406: colors.grey[406],
      409: colors.grey[409],
      410: colors.grey[410],
      455: colors.grey[455],
      480: colors.grey[480],
      500: colors.grey[500],
      510: colors.grey[510],
      520: colors.grey[520],
      600: colors.grey[600],
      960: colors.grey[960],
      1000: colors.grey[1000],
      1200: colors.grey[1200],
      1400: colors.grey[1400],
      1425: colors.grey[1425],
      1450: colors.grey[1450],
      1500: colors.grey[1500],
      1560: colors.grey[1560],
      1579: colors.grey[1579],
      1580: colors.grey[1580],
      1584: colors.grey[1584],
      1585: colors.grey[1585],
      1590: colors.grey[1590],
      1680: colors.grey[1680],
      1700: colors.grey[1700],
      1710: colors.grey[1710],
      1725: colors.grey[1725],
      1750: colors.grey[1750],
      1755: colors.grey[1755],
      1780: colors.grey[1780],
      1800: colors.grey[1800],
      1890: colors.grey[1890],
      1895: colors.grey[1895],
      1900: colors.grey[1900],
      2000: colors.grey[2000],
      2700: colors.grey[2700],
      2900: colors.grey[2900],
      3000: colors.grey[3000],
      3100: colors.grey[3100],
      3200: colors.grey[3200],
      3300: colors.grey[3300],
      3500: colors.grey[3500],
      3800: colors.grey[3800],
    },

    chart: {
      background: {
        blue: colors.blue[400],
        green: colors.teal[800],
        grey: colors.grey[1590],
        red: colors.red[200],
      },
    },

    messagingProvider: {
      whatsApp: colors.green[1600],
      viber: colors.purple[1000],
    },

    serviceStatus: {
      minor: colors.blue[150],
      major: colors.yellow[100],
      critical: colors.red[430],
    },
  },

  typography: {
    fontFamily: `"Roboto", Helvetica, Arial, sans-serif`,
  },

  customShadows: {
    selectorMenu: "0px 2px 3px 1px rgba(0, 0, 0, 0.16)",
  },
});

export default theme;
